import React from "react";
import "./index.css";
import { ReactComponent as Check } from "../../assets/icons/check.svg";
import parse from "html-react-parser";
const PriceComponent = ({ loading, data }) => {
  const CustomListItem = ({ children }) => (
    <div className="plan-fetaure-div">
      <Check width={18} height={18} />
      <span className="plan-features-text">{children}</span>
    </div>
  );

  const PlanDescription = ({ description }) => {
    const customRender = (node) => {
      if (node.type === "tag" && node.name === "li") {
        return (
          <CustomListItem key={node.children[0]?.data}>
            {parse(node.children.map((child) => child.data).join(""))}
          </CustomListItem>
        );
      }
    };

    return <div>{parse(description, { replace: customRender })}</div>;
  };

  return (
    <div className="price-section-container">
      <div className="price-section-div">
        <div className="price-section-1">
          <div className="pricing-label">Pricing</div>
          <p className="pricing-desc-text">
            Find the right plan for your needs
          </p>
        </div>
        <div className="pricing-plan-div">
          {loading
            ? Array.from({ length: 2 }).map((_, index) => (
                <div
                  className="shimmer-div plan-price-card"
                  style={{ minHeight: 300 }}
                >
                  <div className="shimmer-image"></div>
                  <div className="shimmer-info">
                    <div className="shimmer-date"></div>
                    <div className="shimmer-title"></div>
                  </div>
                </div>
              ))
            : data?.map((item, index) => {
                return (
                  <div key={item?.id} className="plan-price-card">
                    <div className="display-column-gap-20">
                      <p className="plan-name-text">{item?.name}</p>
                      {item?.description && (
                        <PlanDescription description={item?.description} />
                      )}
                    </div>
                    <div className="plan-price-section">
                      <div className="display-column-gap-6">
                        <p className="price-plan-text">
                          ${item?.monthly}
                          <span className="package-text">/month</span>
                        </p>
                        <p
                          className="package-text"
                          style={{
                            textAlign: "center",
                            fontWeight: "400",
                            margin: 0,
                          }}
                        >
                          or
                        </p>
                        <p className="price-plan-text">
                          ${item?.yearly}
                          <span className="package-text">/year</span>
                        </p>
                      </div>
                      <button
                        className="common-btn"
                        onClick={() =>
                          (window.location.href =
                            "https://dev.myretasker.com/#/sign-in")
                        }
                      >
                        Get Started
                      </button>
                    </div>
                  </div>
                );
              })}
        </div>
      </div>
    </div>
  );
};

export default PriceComponent;
