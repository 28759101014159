import React, { useState } from "react";
import "./index.css";
import { faqData } from "../../helper/config";
import { ReactComponent as DropDown } from "../../assets/icons/dropdownArrow.svg";

const FaqComponent = () => {
  const [faqs, setFaqs] = useState(faqData);

  const toggleVisibility = (id) => {
    setFaqs((prevFaqs) =>
      prevFaqs.map((faq) =>
        faq.id === id ? { ...faq, visible: !faq.visible } : faq
      )
    );
  };

  return (
    <div className="faq-section">
      <div className="faq-section-1">
        <div className="common-label">FAQs</div>
        <p className="about-desc-text" style={{ textAlign: "left" }}>
          Got questions? we got answers
        </p>
      </div>
      <div className="faq-section-2">
        {faqs.map((faq) => (
          <div className="accordiation-card" key={faq.id}>
            <div
              onClick={() => toggleVisibility(faq.id)}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <h4 className="faq-qestion-text">{faq.question}</h4>
              <div
                className={`${
                  faq?.visible ? "dropdown-icon" : "dropdown-icon-1"
                }`}
              >
                <DropDown />
              </div>
            </div>
            {faq.visible && <p className="answer-text">{faq.answer}</p>}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FaqComponent;
