import React, { useEffect, useState } from "react";
import "./home.css";
import Navbar from "../../components/navbar/Navbar";
import HeroSection from "../../components/hero-section/HeroSection";
import AboutComponent from "../../components/home/AboutComponent";
import BenefitsComponent from "../../components/home/BenefitsComponent";
import ServiceComponent from "../../components/home/ServiceComponent";
import HowWorkComponent from "../../components/home/HowWorkComponent";
import PriceComponent from "../../components/home/PriceComponent";
import TestimonialComponent from "../../components/home/TestimonialComponent";
import BlogComponent from "../../components/home/BlogComponent";
import Footer from "../../components/footer/Footer";
import FaqComponent from "../../components/home/FaqComponent";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import {
  fetchHomeBlogFunction,
  visitorApiFunction,
  fetchTestimonialFunction,
  fetchTermData,
  fetchPrivacyData,
  fetchPricingData,
} from "../../actions/homeActions";

const Home = () => {
  const dispatch = useDispatch();
  const naviagte = useNavigate();
  const { isLoading, homeBlogdata, testimonialData, pricingData } = useSelector(
    (state) => state.home
  );

  const fetchIpAddressApi = () => {
    fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then((data) => {
        const deviceID = uuidv4();
        const httpBody = {
          ip: data?.ip,
          device_id: deviceID,
        };
        dispatch(visitorApiFunction(httpBody)).then((response) => {});
      })
      .catch((error) => {});
  };

  useEffect(() => {
    fetchIpAddressApi();
    dispatch(fetchTestimonialFunction());
    dispatch(fetchPricingData());
    dispatch(fetchTermData());
    dispatch(fetchPrivacyData());
    dispatch(fetchHomeBlogFunction({ page: 1, per_page: 3 }));
  }, [dispatch]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="main-container">
      <Navbar />
      <HeroSection />
      <AboutComponent />
      <BenefitsComponent />
      <ServiceComponent />
      <HowWorkComponent />
      <PriceComponent loading={isLoading} data={pricingData} />
      {testimonialData?.length > 0 && (
        <TestimonialComponent loading={isLoading} data={testimonialData} />
      )}
      {homeBlogdata?.length > 0 && (
        <BlogComponent loading={isLoading} data={homeBlogdata} />
      )}
      <FaqComponent />
      <Footer />
    </div>
  );
};

export default Home;
