import {
  blog1,
  blog2,
  blog3,
  blogImage1,
  blogImage2,
  blogImage3,
  blogImage4,
  eleanor,
  jenny,
  savannah,
  service1,
  service2,
  service3,
  wade,
} from "./assets";

export const BASE_URL = "https://myretasker.4tysixapplabs.com/api/";
export const subscribeApiEndPoint = "subscribe_user";
export const conatctUsApiEndPoint = "create_contact_us";
export const blogApiEndPoint = "blog_list";
export const blogDetailsApiEndPoint = "blog_detail";
export const visitorApiEndPoint = "add_visitors";
export const tesimonialApiEndPOint = "testimonial_list";
export const planApiEndPoint = "plans_listing";
export const privacyApiEndPoint = "privacy_policy";
export const termApiEndPoint = "terms_and_condition";

export const benefitsData = [
  {
    id: 1,
    name: "Flexible Solutions",
    desc: "Tailor services to your specific real estate needs with options designed to adapt to your workflow.",
  },
  {
    id: 2,
    name: "Affordable Plans",
    desc: "Access professional assistance without breaking the bank, with pricing that suits every budget.",
  },
  {
    id: 3,
    name: "Expert Support",
    desc: "Work with skilled professionals who bring industry expertise to manage tasks efficiently and effectively.",
  },
];

export const servicesData = [
  {
    id: 1,
    name: "Listing Coordination",
    desc: "Our listing coordination services ensure that your brand and your listings will look good online, giving you the best possible exposure. It includes following core services and more",
    image: service1,
    moreInfoList: [
      "Listing Paperwork Management",
      "Document & Photo Organization",
      "MLS Listing",
      '"Coming Soon" Listing',
      "Social Media & Online Marketing",
      "MLS Support",
      "Listing Updates & Enhancements",
      "Weekly Showing Feedback Reports",
      "Sign and Photography Orders",
      "CRM Listing Updates",
    ],
  },
  {
    id: 2,
    name: "Transaction Coordination",
    desc: "Our experienced TC's will effortlessly handle all your contract-to-close transaction documents and procedures. It includes following core services and more",
    image: service2,
    moreInfoList: [
      "Order title reports, insurance, and appraisals.",
      "Send intro emails with key dates to all parties.",
      "Update brokerage CRM (Dotloop, KWCommand, etc.).",
      "Prepare sale-side paperwork (offers, addendums).",
      "Process paperwork and follow up with third parties.",
      "Communicate with agents on missing documents.",
      "Update MLS throughout the sale.",
      "Weekly Showing Feedback Reports",
      "Create and promote custom 'Under Contract' and 'Just Sold' flyers.",
    ],
  },
  {
    id: 3,
    name: "Branding & Marketing",
    desc: "Our technology & design teams are proficient at creating custom online/offline marketing pieces for your brand. It includes following core services and more",
    image: service3,
    moreInfoList: [
      "Custom 'Just Listed', 'Open House', and 'Sold' flyers.",
      "Social media ads (Facebook, Instagram, Twitter)..",
      "Full-color property brochures and mini brochures.",
      "Custom HTML emailers via MailChimp, Constant Contact.",
      "Digital marketing support & social media management.",
      "Website and landing page development.",
      "Brand identity kit (logo, business card, letterhead).",
      "Custom direct mailing campaigns.",
      "Animated marketing videos from property photos.",
      "Content marketing, blog writing, and keyword research.",
    ],
  },
];

export const howworkSteps = [
  {
    id: 1,
    name: "Sign up",
    desc: "Create your account quickly and easily. Our user-friendly platform makes registration a breeze, so you can get started without any hassle.",
  },
  {
    id: 2,
    name: "Choose service",
    desc: "Browse our service options and select the ones that suit your needs. Whether it's listing coordination or transaction management, we’ve got you covered.",
  },
  {
    id: 3,
    name: "Sit back & enjoy",
    desc: "Once you’ve chosen your services, our experienced team will handle all the details, allowing you to focus on closing deals and growing your business.",
  },
];

export const planPricing = [
  {
    id: 1,
    name: "Basic",
    benefits: [
      "10% discount on ala carte",
      "1 free customized flyer of your choice/month for social media",
    ],
    monthPrice: "$19.99",
    yearPrice: "$189.99",
  },
  {
    id: 2,
    name: "Intermediate",
    benefits: [
      "10% discount on ala carte",
      "2 free customized flyer of your choice/month for social media",
      "Exclusive use to AI based tools",
    ],
    monthPrice: "$34.95",
    yearPrice: "$334.95",
  },
];

export const testimonialData = [
  {
    id: 1,
    country: "florida",
    name: "Wade Warren",
    comment:
      "I’ve been using MyRetasker for several months, and I couldn’t be happier! The virtual assistants are professional, responsive, and always go above and beyond to help me manage my workload. Highly recommend!",
    image: wade,
  },
  {
    id: 2,
    country: "California",
    name: "Jenny Wilson",
    comment:
      "MyRetasker has completely transformed the way I handle my daily tasks. From managing emails to scheduling appointments, their team has been a lifesaver. I now have more time to focus on growing my business.",
    image: jenny,
  },
  {
    id: 3,
    country: "Texas",
    name: "Eleanor Pena",
    comment:
      "The team at MyRetasker is incredibly efficient. They always deliver tasks on time and handle everything with great care. Occasionally, there are small communication hiccups, but overall, their service is top-notch.",
    image: eleanor,
  },
  {
    id: 4,
    country: "Alaska",
    name: "Savannah Nguyen",
    comment:
      "I was skeptical at first, but MyRetasker has proven to be worth every penny. Their services are affordable, and the professionalism they bring to the table is unmatched. I’ll definitely continue using their services!",
    image: savannah,
  },
];

export const blogData = [
  {
    id: 1,
    title: "Mastering Real Estate Paperwork: Tips for Efficient Documentation",
    publishDate: "04 May. 2024",
    image: blog1,
  },
  {
    id: 2,
    title:
      "The Future of Real Estate Documentation: Technology Trends and Innovations",
    publishDate: "04 May. 2024",
    image: blog2,
  },
  {
    id: 3,
    title: "The Ultimate Guide to Streamlining Your Workflow with MyRETasker",
    publishDate: "04 May. 2024",
    image: blog3,
  },
];

export const faqData = [
  {
    id: 1,
    visible: false,
    question: "How affordable are My RE Tasker services?",
    answer:
      "Our services start at just $20, making them accessible to agents of all scales. You only pay for the services you need, without any long-term contracts or hidden fees.",
  },
  {
    id: 2,
    visible: false,
    question: "Are there any hidden charges?",
    answer:
      "No, we believe in transparent pricing. You'll only pay for the services you select, with no unexpected costs.",
  },
  {
    id: 3,
    visible: false,
    question: "What deliverables can I expect from My RE Tasker?",
    answer:
      "Depending on the service chosen, you’ll receive professionally managed timelines, updated contracts, transaction tracking, marketing materials, and more.",
  },
  {
    id: 4,
    visible: false,
    question: "How are the deliverables tailored to my needs?",
    answer:
      "Each service is customized to fit your specific requirements, ensuring that all deliverables align with your business goals.",
  },
  {
    id: 5,
    visible: false,
    question: "Can I scale up the services as my business grows?",
    answer:
      "Absolutely! Our flexible platform allows you to add or adjust services as your business needs evolve, ensuring you get the right level of support at every stage.",
  },
  {
    id: 6,
    visible: false,
    question: "How do I get started with My RE Tasker?",
    answer:
      "Getting started is easy. Just sign up on our platform, select the services you need, and our team will guide you through the rest.",
  },
];

export const blogListsData = [
  {
    id: 1,
    image: blogImage1,
    title: "Mastering Real Estate Paperwork: Tips for Efficient Documentation",
    publishDate: "02 Jan 2025",
    read: "10 minutes",
  },
  {
    id: 2,
    image: blogImage2,
    title:
      "The Future of Real Estate Documentation: Technology Trends and Innovations",
    publishDate: "07 Jan 2025",
    read: "15 minutes",
  },
  {
    id: 3,
    image: blogImage3,
    title: "The Ultimate Guide to Streamlining Your Workflow with MyRETasker",
    publishDate: "10 Jan 2025",
    read: "20 minutes",
  },
  {
    id: 4,
    image: blogImage4,
    title:
      "Navigating Real Estate Paperwork: Strategies for Effective Documentation",
    publishDate: "16 Jan 2025",
    read: "15 minutes",
  },
];

export const TermsList = [
  {
    title: "Introduction",
    description:
      "Welcome to MY RE TASKER, a platform operated by Worldwide Assistance Solutions LLC. By accessing or using our services (the 'Service'), you agree to be bound by these Terms & Conditions (the 'Terms'). Please read them carefully.",
  },
  {
    title: "Definitions",
    description: "",
    list: [
      '"Company" refers to Worldwide Assistance Solutions LLC, dba MY RE TASKER.',
      `"Service" refers to the Company's SAAS platform available via website and mobile applications.`,
      '"User", "You, and Your" refer to the individual accessing or using the Service.',
    ],
  },
  {
    title: "Acceptance of Terms",
    description:
      "By accessing or using our Service, you acknowledge that you have read, understood, and agree to be bound by these Terms. If you do not agree with any part of the Terms, you may not use the Service.",
  },
  {
    title: "Eligibility",
    description:
      "To use certain features of the Service, you may be required to create an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete. You are responsible for safeguarding your account information.",
  },
  {
    title: "Account Registration",
    description:
      "To use certain features of the Service, you may be required to create an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete. You are responsible for safeguarding your account information.",
  },
  {
    title: "License to Use",
    description:
      "The Company grants you a limited, non-exclusive, non-transferable, revocable license to access and use the Service for your personal or business use, subject to these Terms.",
  },
  {
    title: "Prohibited Conduct",
    description:
      "You agree not to engage in any of the following prohibited activities:",
    list: [
      "Using the Service for any illegal purpose.",
      "Attempting to interfere with the proper functioning of the Service.",
      "Using automated systems to access the Service without the Company's prior written permission.",
      "Attempting to gain unauthorized access to any part of the Service.",
    ],
  },
  {
    title: "Fees and Payment",
    description:
      "Some features of the Service may require payment of fees. You agree to pay all applicable fees associated with your use of the Service. The Company reserves the right to change its pricing and fees at any time.",
  },
  {
    title: "Intellectual Property",
    description:
      "All content, trademarks, service marks, trade names, logos, and other intellectual property displayed on the Service are the property of the Company or its licensors. You may not use any of these without the Company's prior written consent.",
  },
  {
    title: "Privacy Policy",
    description:
      "Your use of the Service is also governed by our Privacy Policy, which is incorporated into these Terms by reference. Please review our Privacy Policy to understand our practices.",
  },
  {
    title: "Termination",
    description:
      "The Company may terminate or suspend your access to the Service at any time, with or without cause or notice, effective immediately. Upon termination, your right to use the Service will immediately cease.The Company grants you a limited, non-exclusive, non-transferable, revocable license to access and use the Service for your personal or business use, subject to these Terms.",
  },
  {
    title: "Disclaimers",
    description:
      'The Service is provided "as is" and "as available" without warranties of any kind, either express or implied. The Company disclaims all warranties, including but not limited to, implied warranties of merchantability, fitness for a particular purpose, and non-infringement.',
  },
  {
    title: "Limitation of Liability",
    description:
      "In no event shall the Company be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from (a) your use or inability to use the Service; (b) any unauthorized access to or use of our servers and/or any personal information stored therein; (c) any interruption or cessation of transmission to or from the Service; (d) any bugs, viruses, trojan horses, or the like that may be transmitted to or through the Service by any third party; or (e) any errors or omissions in any content or for any loss or damage of any kind incurred as a result of your use of any content posted, emailed, transmitted, or otherwise made available via the Service, whether based on warranty, contract, tort (including negligence), or any other legal theory, and whether or not the Company has been advised of the possibility of such damages.",
  },
  {
    title: "Governing Law",
    description:
      "These Terms shall be governed and construed in accordance with the laws of the State of Florida, without regard to its conflict of law provisions.",
  },
  {
    title: "Changes to Terms",
    description:
      "The Company reserves the right to modify or replace these Terms at any time. If a revision is material, we will provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.",
  },
  {
    title: "Contact Us",
    description: `If you have any questions about these Terms, please contact us at: Worldwide Assistance Solutions LLC 1504 Bay Road, Unit 3302 Miami Beach, FL 33139 Email: Info@myretasker.com`,
  },
];
export const privacyList = [
  {
    title: "Introduction",
    content: `Worldwide Assistance Solutions LLC, dba MY RE TASKER ("Company," "we," "us," or "our"), operates the MY RE TASKER platform available via website and mobile applications. We are committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our Service.`,
  },
  {
    title: "Information We Collect",
    content:
      "We may collect information about you in a variety of ways. The information we may collect includes:",
    subsections: [
      {
        num: "2.1",
        title: "Personal Data",
        content:
          "Personally identifiable information, such as your name, shipping address, email address, and telephone number, and demographic information, such as your age, gender, hometown, and interests, that you voluntarily give to us when you register with the Service or when you choose to participate in various activities related to the Service.",
      },
      {
        num: "2.2",
        title: "Derivative Data",
        content:
          "Information our servers automatically collect when you access the Service, such as your IP address, your browser type, your operating system, your access times, and the pages you have viewed directly before and after accessing the Service.",
      },
      {
        num: "2.3",
        title: "Financial Data",
        content:
          "Financial information, such as data related to your payment method (e.g., valid credit card number, card brand, expiration date) that we may collect when you purchase, order, return, exchange, or request information about our services from the Service.",
      },
      {
        num: "2.4",
        title: "Mobile Device Data",
        content:
          "Device information, such as your mobile device ID, model, and manufacturer, and information about the location of your device if you access the Service from a mobile device.",
      },
    ],
  },
  {
    title: "Use of Your Information",
    content: `Having accurate information about you permits us to provide you with a smooth, efficient, and customized experience. Specifically, we may use information collected about you via the Service to:`,
    list: [
      "Create and manage your account",
      "Process your transactions",
      "Personalize your experience with the Service",
      "Improve our Service",
      "Send you promotional information",
      "Respond to your comments, questions, and customer service requests",
      "Protect against fraudulent, unauthorized, or illegal activity",
      "Monitor and analyze usage and trends to improve your experience with the Service",
    ],
  },
  {
    title: "Disclosure of Your Information",
    content:
      "We may share information we have collected about you in certain situations. Your information may be disclosed as follows:",
    subsections: [
      {
        num: "4.1",
        title: "By Law or to Protect Rights",
        content:
          "If we believe the release of information about you is necessary to respond to legal process, to investigate or remedy potential violations of our policies, or to protect the rights, property, and safety of others, we may share your information as permitted or required by any applicable law, rule, or regulation.",
      },
      {
        num: "4.2",
        title: "Business Transfers",
        content:
          "We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.",
      },
      {
        num: "4.3",
        title: "Third-Party Service Providers",
        content:
          "We may share your information with third parties that perform services for us or on our behalf, including payment processing, data analysis, email delivery, hosting services, customer service, and marketing assistance.",
      },
      {
        num: "4.4",
        title: "Marketing Communications",
        content:
          "With your consent, we may share your information with third parties for marketing purposes, as permitted by law.",
      },
    ],
  },
  {
    title: "Security of Your Information",
    content:
      "We use administrative, technical, and physical security measures to help protect your personal information. While we have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other type of misuse.",
  },
  {
    title: "Your Rights and Choices",
    subsections: [
      {
        num: "6.1",
        title: "Account Information",
        content:
          "You may at any time review or change the information in your account or terminate your account by contacting us. Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, some information may be retained in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our Terms & Conditions, and/or comply with legal requirements.",
      },
      {
        num: "6.2",
        title: "Emails and Communications",
        content:
          "If you no longer wish to receive correspondence, emails, or other communications from us, you may opt-out by contacting us using the contact information provided below or by using the unsubscribe feature in the email.",
      },
    ],
  },
  {
    title: "Changes to This Privacy Policy",
    content:
      "We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.",
  },
  {
    title: "Contact Us",
    content:
      "If you have any questions or comments about this Privacy Policy, please contact us at: Worldwide Assistance Solutions LLC 1504 Bay Road, Unit 3302 Miami Beach, FL 33139 Email: info@myretasker.com",
  },
];
