import {
  VISITOR_API_FAILURE,
  VISITOR_API_REQUEST,
  VISITOR_API_SUCCESS,
  SUBSCRIBE_API_REQUEST,
  SUBSCRIBE_API_SUCCESS,
  SUBSCRIBE_API_FAILURE,
  CONTACT_US_API_SUCCESS,
  CONTACT_US_API_FAILURE,
  CONTACT_US_API_REQUEST,
  FETCH_HOME_BLOG_SUCCESS,
  FETCH_HOME_BLOG_FAILURE,
  FETCH_HOME_BLOG_REQUEST,
  FETCH_SINGLE_BLOG_DETAILS_REQUEST,
  FETCH_SINGLE_BLOG_DETAILS_FAILURE,
  FETCH_SINGLE_BLOG_DETAILS_SUCCESS,
  FETCH_BLOG_SUCCESS,
  FETCH_BLOG_REQUEST,
  FETCH_BLOG_FAILURE,
  FETCH_TESTIMONIAL_REQUEST,
  FETCH_TESTIMONIAL_FAILURE,
  FETCH_TESTIMONIAL_SUCCESS,
  FETCH_PRICING_FAILURE,
  FETCH_PRICING_REQUEST,
  FETCH_PRICING_SUCCESS,
  FETCH_PRIVACY_FAILURE,
  FETCH_PRIVACY_REQUEST,
  FETCH_PRIVACY_SUCCESS,
  FETCH_TERM_FAILURE,
  FETCH_TERM_REQUEST,
  FETCH_TERM_SUCCESS,
} from "../actions/homeActions";

const initialState = {
  isLoading: false,
  subscribeLoading: false,
  contactUsLoading: false,
  homeBlogdata: [],
  blogDetails: {},
  filters: {},
  page: 1,
  blogTotalPage: 1,
  moreLoading: false,
  blogData: [],
  testimonialData: [],
  pricingData: [],
  privacyData: [],
  termData: [],
};

const homeReducer = (state = initialState, action) => {
  switch (action.type) {
    case VISITOR_API_REQUEST:
      return { ...state, isLoading: true };
    case VISITOR_API_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case VISITOR_API_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case SUBSCRIBE_API_REQUEST:
      return { ...state, subscribeLoading: true };
    case SUBSCRIBE_API_SUCCESS:
      return {
        ...state,
        subscribeLoading: false,
      };
    case SUBSCRIBE_API_FAILURE:
      return {
        ...state,
        subscribeLoading: false,
      };
    case CONTACT_US_API_REQUEST:
      return { ...state, contactUsLoading: true };
    case CONTACT_US_API_SUCCESS:
      return {
        ...state,
        contactUsLoading: false,
      };
    case CONTACT_US_API_FAILURE:
      return {
        ...state,
        contactUsLoading: false,
      };
    case FETCH_HOME_BLOG_REQUEST:
      return { ...state, isLoading: true };
    case FETCH_HOME_BLOG_SUCCESS:
      return {
        ...state,
        isLoading: false,
        homeBlogdata: action?.homeBlogdata,
      };
    case FETCH_HOME_BLOG_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case FETCH_SINGLE_BLOG_DETAILS_REQUEST:
      return { ...state, isLoading: true };
    case FETCH_SINGLE_BLOG_DETAILS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        blogDetails: action?.blogDetails,
      };
    case FETCH_SINGLE_BLOG_DETAILS_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case FETCH_BLOG_REQUEST:
      return {
        ...state,
        moreLoading: true,
      };
    case FETCH_BLOG_SUCCESS:
      return {
        ...state,
        blogData: action.blogData,
        filters: action.filters,
        blogTotalPage: action.totalPage,
        moreLoading: false,
      };
    case FETCH_BLOG_FAILURE:
      return {
        ...state,
        moreLoading: false,
        blogData: [],
        blogTotalPage: 1,
        page: 1,
      };
    case FETCH_TESTIMONIAL_REQUEST:
      return { ...state, isLoading: true };
    case FETCH_TESTIMONIAL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        testimonialData: action?.data,
      };
    case FETCH_TESTIMONIAL_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case FETCH_PRICING_REQUEST:
      return { ...state, isLoading: true };
    case FETCH_PRICING_SUCCESS:
      return {
        ...state,
        isLoading: false,
        pricingData: action?.data,
      };
    case FETCH_PRICING_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case FETCH_PRIVACY_REQUEST:
      return { ...state, isLoading: true };
    case FETCH_PRIVACY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        privacyData: action?.data,
      };
    case FETCH_PRIVACY_FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    case FETCH_TERM_REQUEST:
      return { ...state, isLoading: true };
    case FETCH_TERM_SUCCESS:
      return {
        ...state,
        isLoading: false,
        termData: action?.data,
      };
    case FETCH_TERM_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default homeReducer;
