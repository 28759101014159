import React, { useEffect, useState } from "react";
import "./blog.css";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import { blogListsData } from "../../helper/config";
import { useNavigate } from "react-router-dom";
import PaginationComponent from "../../components/pagination/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { fetchBlogFunction } from "../../actions/homeActions";
import { convertDate } from "../../helper/utils";

const BlogPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { blogData, filters, blogTotalPage, moreLoading } = useSelector(
    (state) => state.home
  );
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    dispatch(fetchBlogFunction(filters, page));
  };

  useEffect(() => {
    setCurrentPage(1);
    dispatch(fetchBlogFunction({ per_page: 10 }, 1));
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const ShimmerEffect = () => (
    <div className="shimmer-div blog-div">
      <div className="shimmer-image "></div>
      <div className="shimmer-info">
        <div className="shimmer-date"></div>
        <div className="shimmer-title"></div>
      </div>
    </div>
  );
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="main-container">
      <Navbar />
      <div className="service-container">
        <h2 className="service-header-text">Blogs</h2>
        <div className="blog-page-list-div">
          {moreLoading
            ? Array(4)
                .fill(0)
                .map((_, index) => <ShimmerEffect key={index} />)
            : blogData?.map((item) => {
                return (
                  <div
                    key={item?.id}
                    className="blog-div"
                    onClick={() => navigate(`/blog-details?id=${item?.id}`)}
                  >
                    <div className="blog-image">
                      <img src={item?.image} className="common-img" />
                    </div>
                    <div className="blog-info-div">
                      <div className="blog-date-info-div">
                        <p className="blog-date-text">
                          {convertDate(item?.updated_at)}
                        </p>
                        <p className="blog-date-text">
                          {item?.read_time} min Read
                        </p>
                      </div>
                      <p className="blog-common-title-text">{item?.title}</p>
                    </div>
                  </div>
                );
              })}
        </div>
        {blogTotalPage > 1 && (
          <div style={{ marginTop: "auto" }}>
            <PaginationComponent
              totalPages={blogTotalPage}
              currentPage={currentPage}
              onPageChange={handlePageChange}
            />
          </div>
        )}
      </div>
      <div style={{ marginTop: "auto" }}>
        <Footer />
      </div>
    </div>
  );
};

export default BlogPage;
