import React from "react";
import "./index.css";
import { benefitsData } from "../../helper/config";

const BenefitsComponent = () => {
  return (
    <div className="benefit-div">
      <div className="about-info-div">
        <div className="common-label">Benefits</div>
        <div className="display-column-gap-8 ">
          <p className="about-desc-text">The smart choice you can make</p>
          <p className="benefit-desc-text">
            Pay per service, no contracts, starting at $20
          </p>
        </div>
      </div>
      <div className="benefit-list-div">
        {benefitsData?.map((item) => {
          return (
            <div key={item?.id} className="benefit-info-div">
              <p className="benefit-card-title-text">{item?.name}</p>
              <p className="benefit-card-desc-text">{item?.desc}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default BenefitsComponent;
