export const iPad = require("../assets/images/ipad.webp");

export const about = require("../assets/images/about.webp");

export const service1 = require("../assets/images/service1.webp");
export const service2 = require("../assets/images/service2.webp");
export const service3 = require("../assets/images/service3.webp");

export const wade = require("../assets/images/wade.webp");
export const jenny = require("../assets/images/jenny.webp");
export const eleanor = require("../assets/images/eleanor.webp");
export const savannah = require("../assets/images/savannah.webp");

export const blog1 = require("../assets/images/blog1.webp");
export const blog2 = require("../assets/images/blog2.webp");
export const blog3 = require("../assets/images/blog3.webp");

export const blogImage1 = require("../assets/images/blogs/blog1.webp");
export const blogImage2 = require("../assets/images/blogs/blog2.webp");
export const blogImage3 = require("../assets/images/blogs/blog3.webp");
export const blogImage4 = require("../assets/images/blogs/blog4.webp");

export const close = require("../assets/icons/close.png");
