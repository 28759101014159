import React, { useState } from "react";
import "./footer.css";
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "../../assets/icons/footer-logo.svg";
import { ReactComponent as Facebook } from "../../assets/icons/facebook.svg";
import { ReactComponent as Twitter } from "../../assets/icons/twitter.svg";
import { ReactComponent as Instagram } from "../../assets/icons/instagram.svg";
import { ReactComponent as LinkedIn } from "../../assets/icons/linkedin.svg";
import Loader from "../common/Loader";
import { useDispatch, useSelector } from "react-redux";
import {
  notifyError,
  notifyInfo,
  notifySuccess,
  validateEmail,
} from "../../helper/utils";
import { subscribeApiFunction } from "../../actions/homeActions";
import { Modal } from "@mui/material";
import { privacyList, TermsList } from "../../helper/config";
import { close } from "../../helper/assets";

const Footer = () => {
  const dispatch = useDispatch();
  const { subscribeLoading, privacyData, termData } = useSelector(
    (state) => state.home
  );
  const [email, setEmail] = useState("");
  const [termModal, setTermModal] = useState(false);
  const [policyModal, setPolicyModal] = useState(false);

  const handleTermOpen = () => {
    setTermModal(true);
  };
  const handleTermClose = () => {
    setTermModal(false);
  };
  const handlePolicyClose = () => {
    setPolicyModal(false);
  };
  const handlePolicyOpen = () => {
    setPolicyModal(true);
  };

  const subscribeHandler = () => {
    if (validateEmail(email)) {
      const httpBody = {
        email: email,
      };
      dispatch(subscribeApiFunction(httpBody)).then((response) => {
        if (response?.success) {
          notifySuccess("Thank you for subscribing! 🎉");
          setEmail("");
        } else {
          if (response?.messages?.email) {
            notifyInfo("This email is already subscribed. 😊");
            setEmail("");
          } else {
            notifyError(response);
          }
        }
      });
    } else {
      notifyError("Please enter valid email address");
    }
  };
  return (
    <div className="footer-main-div">
      <div className="footer-container">
        <div className="footer-section-1">
          <div className="flex-1 flex-column-20">
            <div>
              <p className="footer-highlight-text">Navigations</p>
            </div>
            <div className="footer-link-div">
              <Link className="footer-common-text" to={"/"}>
                Home
              </Link>
              <Link className="footer-common-text" to={"/services"}>
                Services
              </Link>
              <Link className="footer-common-text" to={"/blogs"}>
                Blogs
              </Link>
              <Link className="footer-common-text" to={"/contact-us"}>
                Contacts
              </Link>
            </div>
          </div>
          <div className="flex-1 flex-column-20">
            <div>
              <p className="footer-highlight-text">Services</p>
            </div>
            <div className="footer-link-div">
              <Link className="footer-common-text" to={"#"}>
                Listing Coordination
              </Link>
              <Link className="footer-common-text" to={"#"}>
                Transaction Coordination
              </Link>
              <Link className="footer-common-text" to={"#"}>
                Branding & Marketing
              </Link>
            </div>
          </div>
          <div className="flex-1 flex-column-sb">
            <div className="flex-column-14">
              <p className="footer-highlight-text">Newsletter</p>
              <div className="subscribe-input-div">
                <input
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value.replace(/\s/g, ""))}
                  placeholder="Email Address"
                  className="footer-email-text-input"
                />
                <button
                  disabled={subscribeLoading}
                  className="subscribe-btn"
                  onClick={subscribeHandler}
                >
                  {subscribeLoading ? (
                    <Loader color={"#34424B"} />
                  ) : (
                    "Subscribe"
                  )}
                </button>
              </div>
            </div>
            <div className="footer-social-div">
              <a
                href="https://www.facebook.com/profile.php?id=61567471440789"
                className="social-link"
              >
                <Facebook />
              </a>
              {/* <a href="#" className="social-link">
                <Twitter />
              </a> */}
              <a
                href="https://www.instagram.com/myretasker/"
                className="social-link"
              >
                <Instagram />
              </a>
              <a
                href="https://www.linkedin.com/company/my-re-tasker"
                className="social-link"
              >
                <LinkedIn />
              </a>
            </div>
          </div>
        </div>
        <div className="divider-line" />
        <div className="footer-section-2">
          <p className="footer-common-text order-2">
            © 2024 My RE Tasker. All rights reserved.
          </p>
          <div className="display-flex-center-10 order-1">
            <Logo />
            <span className="footer-logo-text">My RE Tasker</span>
          </div>
          <div className="display-flex-12 order-3">
            <Link className="footer-common-text" onClick={handleTermOpen}>
              Term & Condition
            </Link>
            <Link className="footer-common-text" onClick={handlePolicyOpen}>
              Privacy Policy
            </Link>
          </div>
        </div>
      </div>
      <Modal open={termModal} onClose={handleTermClose}>
        <div className="modalBoxWaitlist">
          <div className="img-div">
            <img
              src={close}
              className="term-modal-close"
              onClick={handleTermClose}
              alt="close-icon"
            />
          </div>
          <div className="term-modal-title">
            <p className="setTitle">Terms & Conditions</p>
          </div>
          <div style={{ overflowY: "auto" }}>
            <div
              className="containerTerm"
              dangerouslySetInnerHTML={{ __html: termData?.description }}
            >
              {/* <ol type="1" style={{ padding: 0 }}>
                {TermsList.map((item, index) => {
                  return (
                    <li className="listTxt">
                      <span className="agreementBold">{item.title}</span>{" "}
                      {item.description}
                      {item.list && (
                        <ul style={{ padding: 0 }}>
                          {item.list.map((listItem, listIndex) => (
                            <li key={listIndex} className="listTxtInner1">
                              {listItem}
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  );
                })}
              </ol> */}
            </div>
          </div>
        </div>
      </Modal>
      <Modal open={policyModal} onClose={handlePolicyClose}>
        <div className="modalBoxWaitlist">
          <div className="img-div">
            <img
              src={close}
              className="term-modal-close"
              onClick={handlePolicyClose}
              alt="close-icon"
            />
          </div>
          <div className="term-modal-title">
            <p className="setTitle">Privacy Policy</p>
          </div>

          <div style={{ overflowY: "auto" }}>
            <div
              className="containerTerm"
              dangerouslySetInnerHTML={{ __html: privacyData?.description }}
            >
              {/* <ol type="1" style={{ padding: 0 }}>
                {privacyList.map((item) => {
                  return (
                    <li className="listTxt">
                      <span className="agreementBold">{item.title}</span>
                      <p className="listTxtDec">{item.content} </p>
                      {item.list && (
                        <ul style={{ padding: 0 }}>
                          {item.list.map((list) => {
                            return <li className="listTxtInner1">{list}</li>;
                          })}
                        </ul>
                      )}
                      {item.subsections && (
                        <ol style={{ padding: 0 }}>
                          {item.subsections.map((items) => {
                            return (
                              <li className="privacyInnerList">
                                <span className="agreementBold lineHeight">
                                  {items.num} {"  "}
                                </span>
                                <span className="agreementBold lineHeight">
                                  {items.title}
                                </span>
                                <p className="listTxt">{items.content} </p>
                              </li>
                            );
                          })}
                        </ol>
                      )}
                    </li>
                  );
                })}
              </ol> */}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Footer;
