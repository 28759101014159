import React from "react";
import { Pagination, PaginationItem, Box } from "@mui/material";
import { Height } from "@mui/icons-material";

const PaginationComponent = ({ totalPages, currentPage, onPageChange }) => {
  const paginationStyles = {
    "& .MuiPaginationItem-root": {
      color: "#000000",
      fontSize: "14px",
      lineHeight: "17.07px",
      fontFamily: "Montserrat",
      fontWeight: "600",
      width: "40px",
      height: "40px",
      borderRadius: "100px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    "& .Mui-selected": {
      backgroundColor: "#1D4E93 !important",
      color: "#fff",
      border: "none",
    },
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      gap={2}
      mt={3}
    >
      <Pagination
        count={totalPages}
        page={currentPage}
        onChange={(event, page) => onPageChange(page)}
        sx={paginationStyles}
        renderItem={(item) => {
          if (item.type === "previous") {
            return (
              <PaginationItem
                {...item}
                sx={{
                  border: "none",
                  backgroundColor: "transparent",
                  color: "#000000 !important",
                  marginRight: "20px",
                  "&:hover": { backgroundColor: "#FFFFFF" },
                }}
              >
                Previous
              </PaginationItem>
            );
          }
          if (item.type === "next") {
            return (
              <PaginationItem
                {...item}
                sx={{
                  border: "none",
                  backgroundColor: "transparent",
                  color: "#000000 !important",
                  marginLeft: "20px",
                  "&:hover": { backgroundColor: "#FFFFFF" },
                }}
              >
                Next
              </PaginationItem>
            );
          }
          return (
            <PaginationItem
              {...item}
              sx={{
                // border: "1px solid #5A189A",
                backgroundColor: "transparent",
                margin: "0 6px",
                "&:hover": { backgroundColor: "#FFFFFF" },
              }}
            />
          );
        }}
      />
    </Box>
  );
};

export default PaginationComponent;
