import React from "react";
import "./index.css";
import { about } from "../../helper/assets";
import { useNavigate } from "react-router-dom";

const AboutComponent = () => {
  const navigate = useNavigate();
  return (
    <div className="about-main-div">
      <div className="about-info-div">
        <div className="common-label">About Us</div>
        <p className="about-desc-text">What we do at My RE Tasker?</p>
      </div>
      <div className="about-more-info-section">
        <div className="about-image-div">
          <img src={about} className="common-img" />
        </div>
        <div className="about-more-info-div">
          <p className="about-more-info-text mobile-text-center">
            At My RE Tasker, we combine expert virtual assistant and smart
            technology to help real estate agents manage their transactions
            easily and affordably.
          </p>
          <button
            className="common-btn mobile-align-self-center"
            onClick={() => navigate("/services")}
          >
            Explore Services
          </button>
        </div>
      </div>
    </div>
  );
};

export default AboutComponent;
