import React, { useEffect } from "react";
import "./blog.css";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import { fetchSingleBlogDetails } from "../../actions/homeActions";
import { useLocation } from "react-router-dom";
import { convertDate } from "../../helper/utils";

const BlogDetailsPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { blogDetails, isLoading } = useSelector((state) => state.home);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const blogId = searchParams.get("id");
    dispatch(fetchSingleBlogDetails({ blog_id: blogId }));
  }, [location, dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="main-container">
      <Navbar />
      <div className="blog-details-main">
        {isLoading ? (
          <div className="shimmer-div">
            <div className="shimmer-title" style={{ width: "100%" }}></div>
            <div className="display-flex-justify-sb">
              <div className="shimmer-date"></div>
              <div className="shimmer-date"></div>
            </div>
            <div
              className="skeleton-img"
              style={{ background: "#e0e0e0" }}
            ></div>

            <div
              className="shimmer-title"
              style={{ width: "100%", height: "100px" }}
            ></div>
          </div>
        ) : (
          <>
            {blogDetails?.title && (
              <h2 className="blog-details-title-text">{blogDetails?.title}</h2>
            )}
            <div className="display-flex-justify-sb">
              {blogDetails?.updated_at && (
                <p className="common-blog-text">
                  {convertDate(blogDetails?.updated_at)}
                </p>
              )}
              {blogDetails?.read_time && (
                <p className="common-blog-text">
                  {blogDetails?.read_time} minutes read
                </p>
              )}
            </div>
            {blogDetails?.image && (
              <div className="blog-details-img">
                <img
                  src={blogDetails?.image}
                  className="blog-detail-common-img"
                  alt="blog-img"
                />
              </div>
            )}
            {blogDetails?.description && (
              <p className="common-blog-text" style={{ color: "#00000080" }}>
                {blogDetails?.description}
              </p>
            )}
            {blogDetails?.blog_content && (
              <div
                className="blog-render-html-view"
                dangerouslySetInnerHTML={{ __html: blogDetails?.blog_content }}
              ></div>
            )}
          </>
        )}
      </div>
      <div style={{ marginTop: "auto" }}>
        <Footer />
      </div>
    </div>
  );
};

export default BlogDetailsPage;
