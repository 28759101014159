import React from "react";
import { servicesData } from "../../helper/config";
import { useNavigate } from "react-router-dom";

const ServiceComponent = () => {
  const navigate = useNavigate();
  return (
    <div className="service-main-div">
      <div className="about-info-div">
        <div className="common-label">Services</div>
        <p className="about-desc-text">
          Powerful services to elevate your workflow
        </p>
      </div>
      <div className="service-card-div">
        {servicesData?.map((item) => {
          return (
            <div key={item?.id} className={`service-card`}>
              <div className="service-card-info-div">
                <p className="service-card-title-text mobile-text-center order-1">
                  {item?.name}
                </p>
                <div className="service-img-div display-none">
                  <img src={item?.image} className="common-img" />
                </div>
                <p className="service-card-desc-text mobile-text-center order-3">
                  {item?.desc}
                </p>
              </div>
              <div className="service-img-div mobile-display-none">
                <img src={item?.image} className="common-img" />
              </div>
            </div>
          );
        })}
      </div>
      <div style={{ alignSelf: "center" }}>
        <button className="common-btn" onClick={() => navigate("/services")}>
          know More
        </button>
      </div>
    </div>
  );
};

export default ServiceComponent;
