import React from "react";
import { howworkSteps } from "../../helper/config";

const HowWorkComponent = () => {
  return (
    <div className="how-work-main-div">
      <div className="about-info-div">
        <div className="common-label">How it works?</div>
        <p className="about-desc-text">
          Streamline Your Real Estate Workflow: Here's How
        </p>
      </div>
      <div className="how-work-step-div">
        {howworkSteps?.map((item) => {
          return (
            <div key={item?.id} className="how-work-card">
              <p className="benefit-card-title-text">{item?.name}</p>
              <p className="how-work-card-desc-text">{item?.desc}</p>
              <div className="steps-label">{item?.id}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default HowWorkComponent;
