import { toast } from "react-toastify";

export const notifySuccess = (success) => {
  toast.dismiss();
  toast.success(success, {
    position: "top-center",
    autoClose: 2000,
  });
};

export const notifyError = (error) => {
  toast.dismiss();
  toast.error(error, {
    position: "top-center",
    autoClose: 2000,
  });
};

export const notifyInfo = (error) => {
  toast.dismiss();
  toast.info(error, {
    position: "top-center",
    autoClose: 2000,
  });
};

export const validateName = (name) => {
  const nameRegex = /^[a-zA-Z]+( [a-zA-Z]+)*$/;
  return nameRegex.test(name.trim());
};
export const validateEmail = (email) => {
  const emailRegex = /^[a-z0-9.]+@[a-z0-9-]+\.[a-z]{2,}$/;
  return emailRegex.test(email.toLowerCase());
};
export const convertDate = (isoDate) => {
  const date = new Date(isoDate);
  const day = date.getDate().toString().padStart(2, "0"); // Ensure 2-digit day
  const month = date.toLocaleString("default", { month: "long" }); // Full month name
  const year = date.getFullYear();
  return `${day} ${month}, ${year}`;
};
