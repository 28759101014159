import React, { useEffect, useState } from "react";
import "./contact.css";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import { ReactComponent as Facebook } from "./assets/facebook.svg";
import { ReactComponent as Twitter } from "./assets/twitter.svg";
import { ReactComponent as Instagram } from "./assets/insta.svg";
import { ReactComponent as Linkedin } from "./assets/linkedin.svg";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/common/Loader";
import { contactUsApiFunction } from "../../actions/homeActions";
import { notifyError, notifySuccess } from "../../helper/utils";

const ContactUs = () => {
  const dispatch = useDispatch();
  const { contactUsLoading } = useSelector((state) => state.home);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = (data) => {
    const httpBody = {
      first_name: data?.firstName?.trim(),
      last_name: data?.lastName?.trim(),
      email: data?.email,
      phone_number: data?.phone,
      message: data?.message?.trim(),
    };

    dispatch(contactUsApiFunction(httpBody)).then((response) => {
      if (response?.success) {
        notifySuccess(
          "Thank you for reaching out! We will get back to you soon. 😊"
        );
        reset();
      } else {
        notifyError(response || "Network error");
      }
    });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="main-container">
      <Navbar />
      <div className="contact-main-container">
        <div className="contact-us-section-1">
          <div className="contact-flex-column-16">
            <h2 className="contact-header-text">Contact us</h2>
            <p className="contact-desc-text">
              Enhance your document management with MyReTasker's AI-driven
              solutions. Our advanced technologies streamline your documentation
              processes, ensuring efficiency and accuracy. Contact us today to
              transform your document challenges into seamless, automated
              workflows.
            </p>
          </div>
          <div className="contact-flex-column-28">
            <div className="contact-flex-column-10">
              <p className="context-common-label-text">Email</p>
              <a
                href={`mailto:myretasker@gmail.com`}
                className="contact-desc-text"
              >
                myretasker@gmail.com
              </a>
            </div>
            <div className="contact-flex-column-10">
              <p className="context-common-label-text">Phone</p>
              <a href={`tel:+44 7398478372`} className="contact-desc-text">
                +44 7398478372
              </a>
            </div>
            <div className="contact-flex-column-10">
              <p className="context-common-label-text">stay in touch</p>
              <div className="contact-flex-20">
                <a
                  href="https://www.facebook.com/profile.php?id=61567471440789"
                  className="social-link"
                >
                  <Facebook />
                </a>
                {/* <a href="#" className="social-link">
                  <Twitter />
                </a> */}
                <a
                  href="https://www.instagram.com/myretasker/"
                  className="social-link"
                >
                  <Instagram />
                </a>
                <a
                  href="https://www.linkedin.com/company/my-re-tasker"
                  className="social-link"
                >
                  <Linkedin />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="contact-us-section-2">
          <h2 className="contact-header-text display-none-mobile-visible">
            Get in touch
          </h2>
          <form className="contact-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="form-section">
              <label>
                First Name
                <input
                  type="text"
                  {...register("firstName", {
                    required: "First name is required",
                  })}
                  placeholder="Enter First Name"
                />
                {errors.firstName && (
                  <p className="error">{errors.firstName.message}</p>
                )}
              </label>

              <label>
                Last Name
                <input
                  type="text"
                  {...register("lastName", {
                    required: "Last name is required",
                  })}
                  placeholder="Enter Last Name"
                />
                {errors.lastName && (
                  <p className="error">{errors.lastName.message}</p>
                )}
              </label>

              <label>
                Contact Email
                <input
                  type="email"
                  {...register("email", {
                    required: "Email is required",
                    pattern: {
                      value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                      message: "Invalid email",
                    },
                  })}
                  placeholder="Enter Contact Email"
                />
                {errors.email && (
                  <p className="error">{errors.email.message}</p>
                )}
              </label>

              <label>
                Contact Phone Number
                <input
                  type="tel"
                  {...register("phone", {
                    required: "Phone number is required",
                    pattern: {
                      value: /^[+]?[0-9]{10,15}$/,
                      message: "Invalid phone number",
                    },
                  })}
                  placeholder="+1 | Enter Contact Phone Number"
                />
                {errors.phone && (
                  <p className="error">{errors.phone.message}</p>
                )}
              </label>

              <label>
                How can we help you?
                <textarea
                  {...register("message", {
                    required: "Message is required",
                    maxLength: {
                      value: 500,
                      message: "Message cannot exceed 500 characters",
                    },
                  })}
                  placeholder="Write Your Message"
                />
                {errors.message && (
                  <p className="error">{errors.message.message}</p>
                )}
              </label>
            </div>
            <button
              type="submit"
              className={"common-btn"}
              style={{ minWidth: "120px" }}
            >
              {contactUsLoading ? <Loader color={"#fff"} /> : "Submit"}
            </button>
          </form>
        </div>
      </div>
      <div style={{ marginTop: "auto" }}>
        <Footer />
      </div>
    </div>
  );
};

export default ContactUs;
