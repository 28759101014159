import React from "react";
import { useRouteError, useNavigate } from "react-router-dom";
import Navbar from "../../components/navbar/Navbar";
import "./error.css";

const ErrorPage = () => {
  const error = useRouteError();
  const navigate = useNavigate();

  return (
    <div className="main-container">
      <Navbar />
      <div className="error-div">
        <h1 className="timer-style">Oops!</h1>
        <p className="slot-date-text ">
          Sorry, an unexpected error has occurred.
        </p>
        <p>
          <i className="standart-time-text">
            {error?.statusText || error?.message}
          </i>
        </p>
        <button className="common-btn " onClick={() => navigate(-1)}>
          {"Go Back"}
        </button>
      </div>
    </div>
  );
};

export default ErrorPage;
