import React, { useEffect } from "react";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import "./service.css";
import { servicesData } from "../../helper/config";
import { ReactComponent as TickCircle } from "../../assets/icons/tick-circle.svg";

const Service = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="main-container">
      <Navbar />
      <div className="service-container">
        <h2 className="service-header-text">Our Services</h2>
        <div className="service-list-div">
          {servicesData?.map((item) => {
            const name = item?.name?.split(" ");
            return (
              <div key={item?.id} className="service-main-card">
                <div className="services-info-div">
                  <p className="service-title-text">
                    {name[0]} {item?.id === 3 && "&"}{" "}
                    <span style={{ color: "#1D4E93" }}>
                      {name[name?.length - 1]}
                    </span>
                  </p>
                  <p className="service-description-text">{item?.desc}</p>
                  <button
                    className="common-btn"
                    onClick={() =>
                      (window.location.href =
                        "https://dev.myretasker.com/#/sign-in")
                    }
                  >
                    Start now
                  </button>
                  <div className="responsive-service-img-mobile">
                    <img src={item?.image} className="common-img" />
                  </div>
                  <div className="more-info-div-service">
                    <p className="include-text">It Includes :</p>
                    <div>
                      {item?.moreInfoList?.map((item, index) => {
                        return (
                          <div key={index} className="more-info-service">
                            <TickCircle />
                            <span>{item}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="service-page-img-div">
                  <img src={item?.image} className="common-img" />
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div style={{ marginTop: "auto" }}>
        <Footer />
      </div>
    </div>
  );
};

export default Service;
