import React from "react";
import "./index.css";
import { blogData } from "../../helper/config";
import { convertDate } from "../../helper/utils";
import { useSelector } from "react-redux";
import { blog1 } from "../../helper/assets";
import { useNavigate } from "react-router-dom";

const BlogComponent = ({ data, loading }) => {
  const navigate = useNavigate();
  return (
    <div className="blog-section-div">
      <div className="about-info-div">
        <div className="common-label">Blogs</div>
        <p className="about-desc-text">
          Real Estate Insights & Platform Updates
        </p>
      </div>

      <div className="blogs-container">
        {loading
          ? Array.from({ length: 3 }).map((_, index) => (
              <div
                key={index}
                className="blog-card shimmer-div"
                style={{ zIndex: 10 }}
              >
                <div className="shimmer-image common-img"></div>
                <div className="blog-card-info">
                  <div className="shimmer-info"></div>
                  <div className="display-flex-justify-sb">
                    <div className="blog-date-text shimmer-date"></div>
                    <div className="blog-date-text shimmer-date"></div>
                  </div>
                </div>
              </div>
            ))
          : data?.map((item) => {
              return (
                <div
                  key={item?.id}
                  className="blog-card"
                  onClick={() => navigate(`blog-details?id=${item?.id}`)}
                >
                  <img
                    src={item?.image ? item?.image : blog1}
                    // src={blog1}
                    className="common-img"
                  />
                  <div className="blog-overlay" />
                  <div className="blog-card-info">
                    <p className="blog-title-text">{item?.title}</p>
                    <div className="display-flex-justify-sb">
                      <p className="blog-date-text">
                        {convertDate(item?.updated_at)}
                      </p>
                      <p className="blog-date-text">
                        {item?.read_time} min read
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
      </div>
    </div>
  );
};

export default BlogComponent;
