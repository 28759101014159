import React from "react";
import "./hero.css";
import { iPad } from "../../helper/assets";
import { ReactComponent as Elipse } from "../../assets/images/ellipse.svg";
import { useNavigate } from "react-router-dom";

const HeroSection = () => {
  const navigate = useNavigate();
  return (
    <div className="main-hero-div">
      <div className="hero-section-1">
        <p className="hero-title-text">
          Effortless Real Estate Transactions, Tailored for You
        </p>
        <p className="hero-desc-text">
          With our carefully thought-out services, we assist real estate agents
          in becoming more organized.
        </p>
        <div className="hero-section-btn-div">
          <button className="login-btn" onClick={() => navigate("/contact-us")}>
            Contact Us
          </button>
          <button
            className="common-btn"
            onClick={() =>
              (window.location.href = "https://dev.myretasker.com/#/sign-up")
            }
          >
            Start Today
          </button>
        </div>
      </div>
      <img src={iPad} className="ipad-img" />
      <div className="elipse-div">
        <Elipse />
      </div>
    </div>
  );
};

export default HeroSection;
