import React, { useState, useEffect } from "react";
import "./navbar.css";
import { ReactComponent as Logo } from "../../assets/icons/logo.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";

const Navbar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const [activeLink, setActiveLink] = useState("/");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, [windowWidth]);

  return (
    <div className="nav-main-div">
      <div className="nav-container">
        <div className="nav-logo-div" onClick={() => navigate("/")}>
          <Logo />
          <span className="logo-text">MY RE TASKER</span>
        </div>
        <div className="nav-link-div">
          <Link
            to={"/"}
            className={`nav-link ${activeLink === "/" && "active-link"}`}
          >
            Home
          </Link>
          <Link
            to={"/services"}
            className={`nav-link ${
              activeLink === "/services" && "active-link"
            }`}
          >
            Services
          </Link>
          <Link
            to={"/blogs"}
            className={`nav-link ${activeLink === "/blogs" && "active-link"}`}
          >
            Blogs
          </Link>
          <Link
            to={"/contact-us"}
            className={`nav-link ${
              activeLink === "/contact-us" && "active-link"
            }`}
          >
            Contact us
          </Link>
        </div>
        <div className="nav-btn-div">
          <button
            className="login-btn"
            onClick={() =>
              (window.location.href = "https://dev.myretasker.com/#/sign-in")
            }
          >
            Log in
          </button>
          <button
            className="common-btn"
            onClick={() =>
              (window.location.href = "https://dev.myretasker.com/#/sign-up")
            }
          >
            Sign Up
          </button>
        </div>
        <div
          className={`menu-icon menu-icon-display ${isOpen ? "open" : ""}`}
          onClick={toggleMenu}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
        {isOpen && (
          <div className="popup-container">
            <div className="popup">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 16,
                  alignItems: "center",
                }}
              >
                <Link
                  to={"/"}
                  className={`nav-link ${activeLink === "/" && "active-link"}`}
                >
                  Home
                </Link>
                <Link
                  to={"/services"}
                  className={`nav-link ${
                    activeLink === "/services" && "active-link"
                  }`}
                >
                  Services
                </Link>
                <Link
                  to={"/blogs"}
                  className={`nav-link ${
                    activeLink === "/blogs" && "active-link"
                  }`}
                >
                  Blogs
                </Link>
                <Link
                  to={"/contact-us"}
                  className={`nav-link ${
                    activeLink === "/contact-us" && "active-link"
                  }`}
                >
                  Contact us
                </Link>
                <div className="display-flex-10" style={{ marginTop: "8px" }}>
                  <button
                    className="login-btn"
                    onClick={() =>
                      (window.location.href =
                        "https://dev.myretasker.com/#/sign-in")
                    }
                  >
                    Log in
                  </button>
                  <button
                    className="common-btn"
                    onClick={() =>
                      (window.location.href =
                        "https://dev.myretasker.com/#/sign-up")
                    }
                  >
                    Sign Up
                  </button>
                </div>
              </div>
            </div>

            <div className="popup-overlay" onClick={toggleMenu}></div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
