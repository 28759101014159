import axios from "axios";
import {
  BASE_URL,
  blogApiEndPoint,
  blogDetailsApiEndPoint,
  conatctUsApiEndPoint,
  subscribeApiEndPoint,
  visitorApiEndPoint,
  tesimonialApiEndPOint,
  planApiEndPoint,
  privacyApiEndPoint,
  termApiEndPoint,
} from "../helper/config";

export const VISITOR_API_REQUEST = "VISITOR_API_REQUEST";
export const VISITOR_API_SUCCESS = "VISITOR_API_SUCCESS";
export const VISITOR_API_FAILURE = "VISITOR_API_FAILURE";

export const SUBSCRIBE_API_REQUEST = "SUBSCRIBE_API_REQUEST";
export const SUBSCRIBE_API_SUCCESS = "SUBSCRIBE_API_SUCCESS";
export const SUBSCRIBE_API_FAILURE = "SUBSCRIBE_API_FAILURE";

export const CONTACT_US_API_REQUEST = "CONTACT_US_API_REQUEST";
export const CONTACT_US_API_SUCCESS = "CONTACT_US_API_SUCCESS";
export const CONTACT_US_API_FAILURE = "CONTACT_US_API_FAILURE";

export const FETCH_HOME_BLOG_REQUEST = "FETCH_HOME_BLOG_REQUEST";
export const FETCH_HOME_BLOG_SUCCESS = "FETCH_HOME_BLOG_SUCCESS";
export const FETCH_HOME_BLOG_FAILURE = "FETCH_HOME_BLOG_FAILURE";

export const FETCH_SINGLE_BLOG_DETAILS_REQUEST =
  "FETCH_SINGLE_BLOG_DETAILS_REQUEST";
export const FETCH_SINGLE_BLOG_DETAILS_SUCCESS =
  "FETCH_SINGLE_BLOG_DETAILS_SUCCESS";
export const FETCH_SINGLE_BLOG_DETAILS_FAILURE =
  "FETCH_SINGLE_BLOG_DETAILS_FAILURE";

export const FETCH_BLOG_REQUEST = "FETCH_BLOG_REQUEST";
export const FETCH_BLOG_SUCCESS = "FETCH_BLOG_SUCCESS";
export const FETCH_BLOG_FAILURE = "FETCH_BLOG_FAILURE";

export const FETCH_TESTIMONIAL_REQUEST = "FETCH_TESTIMONIAL_REQUEST";
export const FETCH_TESTIMONIAL_SUCCESS = "FETCH_TESTIMONIAL_SUCCESS";
export const FETCH_TESTIMONIAL_FAILURE = "FETCH_TESTIMONIAL_FAILURE";

export const FETCH_PRICING_REQUEST = "FETCH_PRICING_REQUEST";
export const FETCH_PRICING_SUCCESS = "FETCH_PRICING_SUCCESS";
export const FETCH_PRICING_FAILURE = "FETCH_PRICING_FAILURE";

export const FETCH_PRIVACY_REQUEST = "FETCH_PRIVACY_REQUEST";
export const FETCH_PRIVACY_SUCCESS = "FETCH_PRIVACY_SUCCESS";
export const FETCH_PRIVACY_FAILURE = "FETCH_PRIVACY_FAILURE";

export const FETCH_TERM_REQUEST = "FETCH_TERM_REQUEST";
export const FETCH_TERM_SUCCESS = "FETCH_TERM_SUCCESS";
export const FETCH_TERM_FAILURE = "FETCH_TERM_FAILURE";

export const visitorApiFunction = (data) => {
  return async (dispatch) => {
    dispatch({ type: VISITOR_API_REQUEST });
    try {
      const response = await axios.post(
        `${BASE_URL}${visitorApiEndPoint}`,
        JSON.stringify(data),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      dispatch({
        type: VISITOR_API_SUCCESS,
      });

      return response.data;
    } catch (error) {
      if (error?.response?.data) {
        dispatch({
          type: VISITOR_API_FAILURE,
        });

        return error?.response?.data?.message;
      } else {
        dispatch({
          type: VISITOR_API_FAILURE,
        });

        return error.message;
      }
    }
  };
};

export const subscribeApiFunction = (data) => {
  return async (dispatch) => {
    dispatch({ type: SUBSCRIBE_API_REQUEST });
    try {
      const response = await axios.post(
        `${BASE_URL}${subscribeApiEndPoint}`,
        JSON.stringify(data),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      dispatch({
        type: SUBSCRIBE_API_SUCCESS,
      });

      return response.data;
    } catch (error) {
      if (error?.response?.data) {
        dispatch({
          type: SUBSCRIBE_API_FAILURE,
        });

        return error?.response?.data;
      } else {
        dispatch({
          type: SUBSCRIBE_API_FAILURE,
        });

        return error.message;
      }
    }
  };
};

export const contactUsApiFunction = (data) => {
  return async (dispatch) => {
    dispatch({ type: CONTACT_US_API_REQUEST });
    try {
      const response = await axios.post(
        `${BASE_URL}${conatctUsApiEndPoint}`,
        JSON.stringify(data),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      dispatch({
        type: CONTACT_US_API_SUCCESS,
      });

      return response.data;
    } catch (error) {
      if (error?.response?.data) {
        dispatch({
          type: CONTACT_US_API_FAILURE,
        });

        return error?.response?.data;
      } else {
        dispatch({
          type: CONTACT_US_API_FAILURE,
        });

        return error.message;
      }
    }
  };
};

export const fetchHomeBlogFunction = (params) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_HOME_BLOG_REQUEST });
    try {
      const config = {
        params: params,
      };
      const response = await axios.get(`${BASE_URL}${blogApiEndPoint}`, config);

      dispatch({
        type: FETCH_HOME_BLOG_SUCCESS,
        homeBlogdata: response?.data?.data?.data,
      });
      return response.data.data;
    } catch (error) {
      if (error?.response?.data) {
        dispatch({
          type: FETCH_HOME_BLOG_FAILURE,
        });
        return error?.response?.data?.message;
      } else {
        dispatch({
          type: FETCH_HOME_BLOG_FAILURE,
        });
        return error.message;
      }
    }
  };
};

export const fetchSingleBlogDetails = (params) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_SINGLE_BLOG_DETAILS_REQUEST });
    try {
      const config = {
        params: params,
      };
      const response = await axios.get(
        `${BASE_URL}${blogDetailsApiEndPoint}`,
        config
      );

      dispatch({
        type: FETCH_SINGLE_BLOG_DETAILS_SUCCESS,
        blogDetails: response?.data?.data,
      });
      return response.data.data;
    } catch (error) {
      if (error?.response?.data) {
        dispatch({
          type: FETCH_SINGLE_BLOG_DETAILS_FAILURE,
        });
        return error?.response?.data?.message;
      } else {
        dispatch({
          type: FETCH_SINGLE_BLOG_DETAILS_FAILURE,
        });
        return error.message;
      }
    }
  };
};

export const fetchBlogFunction = (filters, page) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_BLOG_REQUEST });
    try {
      const config = {
        params: { ...filters, page },
      };
      const response = await axios.get(`${BASE_URL}${blogApiEndPoint}`, config);

      dispatch({
        type: FETCH_BLOG_SUCCESS,
        blogData: response?.data?.data?.data,
        page,
        filters,
        totalPage: response.data.data.last_page,
      });
      return response.data.data;
    } catch (error) {
      if (error?.response?.data) {
        dispatch({
          type: FETCH_BLOG_FAILURE,
        });
        return error?.response?.data?.message;
      } else {
        dispatch({
          type: FETCH_BLOG_FAILURE,
        });
        return error.message;
      }
    }
  };
};

export const fetchTestimonialFunction = () => {
  return async (dispatch) => {
    dispatch({ type: FETCH_TESTIMONIAL_REQUEST });
    try {
      const response = await axios.get(`${BASE_URL}${tesimonialApiEndPOint}`);

      dispatch({
        type: FETCH_TESTIMONIAL_SUCCESS,
        data: response?.data?.data?.data,
      });
      return response.data.data;
    } catch (error) {
      if (error?.response?.data) {
        dispatch({
          type: FETCH_TESTIMONIAL_FAILURE,
        });
        return error?.response?.data?.message;
      } else {
        dispatch({
          type: FETCH_TESTIMONIAL_FAILURE,
        });
        return error.message;
      }
    }
  };
};

export const fetchPricingData = () => {
  return async (dispatch) => {
    dispatch({ type: FETCH_PRICING_REQUEST });
    try {
      const response = await axios.get(`${BASE_URL}${planApiEndPoint}`);

      dispatch({
        type: FETCH_PRICING_SUCCESS,
        data: response?.data?.data,
      });
      return response.data;
    } catch (error) {
      if (error?.response?.data) {
        dispatch({
          type: FETCH_PRICING_FAILURE,
        });
        return error?.response?.data?.message;
      } else {
        dispatch({
          type: FETCH_PRICING_FAILURE,
        });
        return error.message;
      }
    }
  };
};

export const fetchPrivacyData = () => {
  return async (dispatch) => {
    dispatch({ type: FETCH_PRIVACY_REQUEST });
    try {
      const response = await axios.get(`${BASE_URL}${privacyApiEndPoint}`);

      dispatch({
        type: FETCH_PRIVACY_SUCCESS,
        data: response?.data?.data,
      });
      return response.data;
    } catch (error) {
      if (error?.response?.data) {
        dispatch({
          type: FETCH_PRIVACY_FAILURE,
        });
        return error?.response?.data?.message;
      } else {
        dispatch({
          type: FETCH_PRIVACY_FAILURE,
        });
        return error.message;
      }
    }
  };
};

export const fetchTermData = () => {
  return async (dispatch) => {
    dispatch({ type: FETCH_TERM_REQUEST });
    try {
      const response = await axios.get(`${BASE_URL}${termApiEndPoint}`);

      dispatch({
        type: FETCH_TERM_SUCCESS,
        data: response?.data?.data,
      });
      return response.data;
    } catch (error) {
      if (error?.response?.data) {
        dispatch({
          type: FETCH_TERM_FAILURE,
        });
        return error?.response?.data?.message;
      } else {
        dispatch({
          type: FETCH_TERM_FAILURE,
        });
        return error.message;
      }
    }
  };
};
