import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ToastContainer, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Home from "./pages/home-page/Home";
import Service from "./pages/service/Service";
import BlogPage from "./pages/blogs/BlogPage";
import ContactUs from "./pages/contact-us/ContactUs";
import BlogDetailsPage from "./pages/blogs/BlogDetailsPage";
import ErrorPage from "./pages/error-page/ErrorPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/services",
    element: <Service />,
  },
  {
    path: "/blogs",
    element: <BlogPage />,
  },
  {
    path: "/contact-us",
    element: <ContactUs />,
  },
  {
    path: "/blog-details",
    element: <BlogDetailsPage />,
  },
]);

function App() {
  return (
    <>
      <div className={`main-content`}>
        <RouterProvider router={router} />
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
        draggable
        transition={Bounce}
        theme="light"
        toastClassName={"custom-toast"}
        limit={1}
      />
    </>
  );
}

export default App;
